import React from "react";
import { Layout, Typography } from 'antd';
import notfound from "../assets/images/notfound.svg";
import Footer from "../components/layout/Footer";
const { Title, Paragraph } = Typography;


const { Content } = Layout;


const NotFound = () => {
  return (
    <Layout>
      <Content style={{ padding: '0 50px' }}>
        <div style={{textAlign: "center"}}>
          <Title>Ups... aquesta pàgina no existeix</Title>
          <Paragraph>
            Sembla que la pàgina on estàs intentant accedir no existeix.
            Si tens algun problema posa't en contacte amb <a href="mailto:hola@rescatanimal.com">hola@rescatanimal.com</a>
          </Paragraph>
          <div>
            <img src={notfound} alt="not found" style={{width: "300px"}}/>
          </div>
        </div>
      </Content>
      <Footer/>
    </Layout>
  )
}

export default NotFound;
