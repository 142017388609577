import {Alert, Button} from "antd";
import { BulbTwoTone, MessageOutlined } from "@ant-design/icons";

const ForumAlert = () => {
  const description = (
    <>
      Pots participar a través del fòrum <a style={{whiteSpace: "nowrap", background: "#1890ff", padding: 5, color: "#e6f7ff", fontWeight: "bold", borderRadius: 7, lineHeight: "2.5em"}} href="https://github.com/rescatanimal/community/discussions"><MessageOutlined /> Fòrum</a>
    </>
  )
  return (
    <Alert type="info" message="Tens alguna pregunta o idea per millorar l'aplicació?" description={description} showIcon
         closable icon={<BulbTwoTone/>}/>
  );
};

export default ForumAlert;
