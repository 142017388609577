import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import ShareButton from "../components/ShareButton";
import ApiClient  from "../services/ApiClient";
import MapForm from "../components/MapForm";
import {Layout, Typography, Spin, Space, Image, Divider, PageHeader, Tag} from "antd";
import { pointToLatLng } from "../utils";
import Breadcrumb from "../components/Breadcrumb";
import RescatComents from "./RescatComments";
import SubscriptionSwitch from "../components/SubscriptionSwitch";
import Moment from "moment";
import { states } from "../components/MapCard";
import "./RescatDetail.css";
import Markdown from "../components/Markdown";
import { useAuth } from "../components/Auth";

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;


const RescatDetail = () => {
  const auth = useAuth();
  const [ rescat, setRescat ] = useState(null);
  const { slug } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetch = async () => {
      const result = await ApiClient.get(`/Rescat/from_slug`, {
        params: {
          slug: slug
        }
      });
      if (result.status === 200 && Object.keys(result.data.res).length > 0) {
        setRescat(result.data.res)
      } else {
        history.push('/404');
      }
    }
    fetch();
  }, [slug, history]);

  const images = ((rescat && rescat.images) || []).map((image) => (
    <Image src={`${process.env.REACT_APP_MINIO_SERVER_URL}/images/rescat_image/${image}_image`} height={300} key={image}/>
  ));

  const swLabel = auth.token ? <Text>Notificacions</Text> : null;

  return (
    <Layout>
      <Content className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}>
      {!rescat &&
        <div style={{textAlign: "center"}}>
          <Spin size="large"/>
        </div>
      }
      {rescat &&
        <>
          <PageHeader
            title={rescat.name}
            breadcrumb={<Breadcrumb />}
            subTitle={`Publicat per ${rescat.author.name} ${Moment(rescat.create_date, 'YYYY-MM-DD').fromNow()}`}
            tags={<Tag color={states[rescat.state].color}>{states[rescat.state].text}</Tag>}
            avatar={{src: `https://www.gravatar.com/avatar/${rescat.author.gravatar}?d=identicon`, size: 40}}
            extra={[
              <ShareButton rescat={rescat} key="share"/>,
              <div style={{marginTop: 15}} key="subscription">
                <SubscriptionSwitch rescat={rescat}/> {swLabel}
              </div>
            ]}
          />
          <Divider/>
          <Title level={5}>Descripció</Title>
          <Paragraph><Markdown>{rescat.description}</Markdown></Paragraph>
          <Title level={5}>Última ubicació</Title>
          <Paragraph><Markdown>{rescat.location_description}</Markdown></Paragraph>
          <div style={{marginBottom: 15}}>
          <MapForm value={pointToLatLng(rescat.geog)} />
            </div>
          <Title level={5}>Fotos</Title>
          <Space size={[20, 20]} wrap style={{marginTop: 20}}>
            {images}
          </Space>
          <RescatComents rescat={rescat}/>
        </>
      }
      </Content>
    </Layout>
  );
}

export default RescatDetail;
