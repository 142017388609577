import { Breadcrumb as AntBreadcrump } from "antd";
import { useLocation, Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useAuth } from "./Auth";

const breadcrumbNameMap = {
  '/rescat/add': 'Afegir animal',
};

const Breadcrumb = () => {
  const location = useLocation();
  const auth = useAuth();
  if (!auth.token) {
    return null;
  }
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    if (breadcrumbNameMap[url]) {
      return (
        <AntBreadcrump.Item key={url}>
          <Link to={url}>{breadcrumbNameMap[url]}</Link>
        </AntBreadcrump.Item>
      );
    } else {
      return null
    }
  });
  const breadcrumbItems = [
    <AntBreadcrump.Item key="home">
      <Link to="/"><HomeOutlined /></Link>
    </AntBreadcrump.Item>,
  ].concat(extraBreadcrumbItems);
  return <AntBreadcrump style={{marginBottom: 10}}>{breadcrumbItems}</AntBreadcrump>
};

export default Breadcrumb;

