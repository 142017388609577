import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  Space,
  Alert
} from "antd";
import dog from "../assets/images/rescatanimal-dog.svg";
import cat from "../assets/images/rescatanimal-cat.svg";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import Footer from "../components/layout/Footer";
import { useAuth } from "../components/Auth";
import { useHistory, useLocation } from "react-router-dom";

const { Title, Text } = Typography;
const { Content } = Layout;


const RememberSwitch = (props) => {
  return (
    <Space align="center">
      <Switch {...props} />
      Recorda'm
    </Space>
  )
}


const SignIn = ({ props }) =>  {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const { from } = location.state || { from: { pathname: "/" } };
  const onFinish = async (values) => {
    setLoading(true);
    const result = await auth.signin(values.login, values.password, values.remember);
    if (result) {
      history.replace(from);
    } else {
      setError('Usuari / Contrasenya incorrectes');
    }
    setLoading(false);

  };

  const images = [cat, dog];
  const image = images[Math.floor(Math.random() * images.length)];
  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Inicia sessió</Title>
              <Title className="font-regular text-muted" level={5}>
              Introduïu el vostre correu electrònic i contrasenya per iniciar la sessió
              </Title>
              {error && <Alert type="error" message={error} showIcon/>}
              <Form
                onFinish={onFinish}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Nom usuari"
                  name="login"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="usuari o correu electrònic" prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>}/>
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Contrasenya"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Contrasenya" prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.45)' }}/>}/>
                </Form.Item>
                <div style={{marginTop: -15, textAlign: "right"}}><Text><Link to="/password_reset">Has oblidad la contrasenya?</Link></Text></div>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  initialValue={true}
                >
                  <RememberSwitch/>
                </Form.Item>

                <Form.Item style={{textAlign: "center"}}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", textTransform: "uppercase" }}
                    loading={loading}
                  >
                    Inicia sessió
                  </Button>
                </Form.Item>
                
                <p className="font-semibold text-muted">
                  No tens un compte?{" "}
                  <Link to="/sign-up" className="text-dark font-bold">
                  Registra't
                  </Link>
                </p>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={image} alt="" />
            </Col>
          </Row>
        </Content>
        <Footer/>
      </Layout>
    </>
  );
};

export default SignIn;
