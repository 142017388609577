/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox
} from "antd";
import dog from "../assets/images/rescatanimal-dog.svg";
import cat from "../assets/images/rescatanimal-cat.svg";
import { MailOutlined, KeyOutlined, UserOutlined } from "@ant-design/icons";
import Footer from "../components/layout/Footer";
import { AnonymousApiClient } from "../services/ApiClient";

const { Title } = Typography;
const { Content } = Layout;


const SignUp = () => {

  const history = useHistory();

  const onFinish = async (values) => {
    console.log("Success:", values);
    await AnonymousApiClient.post('/ResUsers/signup', {
      values: {
        name: values.login,
        login: values.login,
        email: values.email,
        password: values.password
      }
    });
    history.replace('/sign-up/confirm');
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const images = [cat, dog];
  const image = images[Math.floor(Math.random() * images.length)];
  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Registra't</Title>
              <Title className="font-regular text-muted" level={5}>
                Empleneu el següent formulari per tal de crear un compte al sistema
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Nom usuari"
                  name="login"
                  rules={[
                    { required: true, message: "Please input your username!" },
                    ({ getFieldValue }) => ({
                      async validator(_, value) {
                        if (value) {
                          const result = await AnonymousApiClient.get(`ResUsers/available?login=${value}`);
                          if (!result.data.res) {
                            return Promise.reject(new Error('Aquest usuari ja està sent utilitzat.'));
                          } else {
                            return Promise.resolve();
                          }
                        }
                      },
                    })
                  ]}
                >
                  <Input placeholder="usuari" prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.45)' }} />} />
                </Form.Item>
                <Form.Item
                  className="username"
                  label="Correu electrònic"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input your email!",
                    },
                    ({ getFieldValue }) => ({
                      async validator(_, value) {
                        if (value) {
                          const result = await AnonymousApiClient.get(`ResUsers/available?email=${value}`);
                          if (!result.data.res) {
                            return Promise.reject(new Error('Aquest mail ja està sent utilitzat.'));
                          } else {
                            return Promise.resolve();
                          }
                        }
                      },
                    })
                  ]}
                >
                  <Input placeholder="usuari@example.com" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.45)' }} />} />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Contrasenya"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Contrasenya" prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />} />
                </Form.Item>
                <Form.Item
                  className="username"
                  name="password2"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Repetir la contrasenya" prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />} />
                </Form.Item>

                <Form.Item name="terms" valuePropName="checked" rules={[
                  {
                    required: true,
                    message: "Has d'acceptar el termes i condicions"
                  }
                ]}>
                  <Checkbox>
                    Estic d'acord amb el{" "}
                    <a href="/terms" className="font-bold text-dark">
                      Termes i condicions
                    </a>
                  </Checkbox>
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", textTransform: "uppercase" }}
                  >
                    Registra't
                  </Button>
                </Form.Item>

                <p className="font-semibold text-muted">
                  Ja tens un compte?{" "}
                  <Link to="/sign-in" className="text-dark font-bold">
                    Inicieu la sessió
                  </Link>
                </p>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={image} alt="" />
            </Col>
          </Row>
        </Content>
        <Footer />
      </Layout>
    </>
  );
}

export default SignUp;
