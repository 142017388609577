import React, { useState } from "react";
import { Layout, Typography, Form, Radio, Space, Button, Input, Upload, DatePicker, Select } from "antd";
import MapForm from "../components/MapForm";
import Footer from "../components/layout/Footer";
import ApiClient from "../services/ApiClient";
import { CameraTwoTone } from "@ant-design/icons";
import ShareButton from "../components/ShareButton";
import Breadcrumb from "../components/Breadcrumb";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;


const RescatResult = (props) => {

  return (
    <>
      <Title style={{textAlign: "center"}}>❤️ Moltes gràcies per registrar el rescat!</Title>
      <div style={{textAlign: "center"}}>
        <ShareButton { ...props }/>
      </div>
    </>
  )
}


const RescatAdd = () => {

  const [ numImages, setNumImages ] = useState(0);
  const [ result, setResult ] = useState(null);

  const handleUpload = async (info) => {
    await info.fileList.forEach(file => {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result.split(',')[1];
      };
      reader.readAsDataURL(file.originFileObj);
    });
  }

  const onFinish = async (values) => {

    const postValues = {
      name: values.name,
      type: values.type,
      geog: `POINT (${values.geog[0]} ${values.geog[1]})`,
      last_seen_date: values.last_seen_date.format('YYYY-MM-DD'),
      state: values.state,
      description: values.description,
      location_description: values.location_description,
    }

    if (values.images.length > 0) {
      postValues.images = await values.images.map((image) => ({image: image.base64}));
    }

    const result = await ApiClient.post('/Rescat', postValues);
    if (result.status === 200) {
      const rescatId = result.data.id;
      const rescat = await ApiClient.get(`/Rescat/${rescatId}`, {
        params: {
          schema: 'id,name,description,slug'
        }
      });
      setResult(rescat.data)
    }
  }

  const getFile = (e) => {
  
    if (Array.isArray(e)) {
      return e;
    }
    setNumImages(e.fileList.length);
   return e && e.fileList;
  };

  return (
    <Layout>
      <Content className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}>
        <Breadcrumb />
        {!result &&
        <>
        <Title>Registra un animal</Title>
        <Title level={5}>Entra les següents dades per registrar un animal vist o perdut</Title>
        <Form onFinish={onFinish} layout="vertical"
          initialValues={{images: []}}
        >
          <Form.Item label="Tipus" name="type"
            rules={[
              {required: true}
            ]}
          >
            <Select style={{width: 150}}>
              <Option value="dog">Gos</Option>
              <Option value="cat">Gat</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Estat" name="state"
            rules={[
              {required: true}
            ]}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="lost">És meu i s'ha perdut</Radio>
                <Radio value="seen">"Vist", semblava perdut</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Descripció curta" name="name"
            rules={[
              {required: true}
            ]}
          >
            <Input placeholder="Gos pastor belga negre adult perdut" />
          </Form.Item>
          <Form.Item label="Descripció llarga" name="description">
            <Input.TextArea placeholder="Gos d'uns quatre anys, molt carinyós. Porta un collar vermell"/>
          </Form.Item>
          <Form.Item label="Fotos" name="images" getValueFromEvent={getFile} valuePropName="fileList">
            <Upload  listType="picture-card" beforeUpload={() => false} maxCount={4} showUploadList={{showPreviewIcon: false}} onChange={handleUpload}>
              { numImages < 4 && <CameraTwoTone style={{fontSize: 43}}/> }
            </Upload>
          </Form.Item>
          <Form.Item label="Data" name="last_seen_date" showTime={true}
            rules={[
              {required: true}
            ]} tooltip="Introdueix la última data en que es va veure">
              <DatePicker/>
          </Form.Item>
          <Form.Item label="Ubicació" name="geog"
            rules={[
              {required: true}
            ]}
          >
            <MapForm />
          </Form.Item>
          <Form.Item label="Descripció ubicacio" name="location_description">
            <Input.TextArea placeholder="A la plaça del poble i voltants"/>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ textTransform: "uppercase" }}
            >
              Envia
            </Button>
          </Form.Item>
        </Form>
        </>
      }
      {result &&
        <>
          <RescatResult rescat={result}/>
        </>
      }
      </Content>
      <Footer />
    </Layout>
  )
};

export default RescatAdd;
