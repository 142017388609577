import {Switch} from "antd";
import {BellOutlined} from "@ant-design/icons";
import { useState } from "react";
import ApiClient from "../services/ApiClient";
import { useAuth } from "./Auth";


const SubscriptionSwitch = (props) => {
  const auth = useAuth();
  const subscribe = async (rescatId, value) => {
    setLoading(true);
    try {
      await ApiClient.patch(`Rescat/${rescatId}`, {
        subscribed: value
      })
      setSubscribed(value);
    } catch (e) {
      console.error(e);
    }
    finally {
      setLoading(false);
    }

  }
  const [ loading, setLoading ] = useState(false);
  const [ subscribed, setSubscribed ] = useState(props.rescat.subscribed);
  if (!auth.token) {
    return null;
  }
  return (
    <>
      <Switch checkedChildren={<BellOutlined/>} unCheckedChildren={<BellOutlined/>} checked={subscribed} onClick={(e) => subscribe(props.rescat.id, e)} loading={loading}/>
    </>
  );
}

export default SubscriptionSwitch;
