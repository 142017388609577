import { useState } from "react";
import { Button, Drawer, Typography, Space } from "antd"
import { ShareAltOutlined } from "@ant-design/icons";
import { rescatToShare } from "../utils";
import {
  WhatsappShareButton, WhatsappIcon,
  FacebookShareButton, FacebookIcon,
  TelegramShareButton, TelegramIcon,
} from "react-share";

const { Title } = Typography;

const shareEnabled = navigator.share;


const ShareDrawer = (props) => {
  return (
    <Drawer visible={props.visible} placement="bottom" onClose={props.onClose}>
      <Title>Compartir...</Title>
      <Space>
        <WhatsappShareButton url={props.data.url}>
          <WhatsappIcon round/>
        </WhatsappShareButton>
        <FacebookShareButton url={props.data.url}>
          <FacebookIcon round/>
        </FacebookShareButton>
        <TelegramShareButton url={props.data.url}>
          <TelegramIcon round/>
        </TelegramShareButton>
      </Space>
    </Drawer>
  )
}

const share = async (data) => {
    try {
      await navigator.share(data);
    } catch (err) {
      console.error(err);
    }
  }

export const ShareIcon = (props) => {
  const [ visible, setVisible ] = useState(false);
  let shareMethod = null;
  if (shareEnabled) {
    shareMethod = share;
  }
  else {
    shareMethod = async (rescat) => {
      setVisible(true);
    }
  }

  const shareComponent = props.button ? (
    <Button icon={<ShareAltOutlined/>} onClick={() => shareMethod(rescatToShare(props.rescat))}>
      Compartir
    </Button>
  ) : (
    <ShareAltOutlined {...props} onClick={() => shareMethod(rescatToShare(props.rescat))} />
  )

  return (
    <>
      {shareComponent}
      {!shareEnabled &&
        <ShareDrawer visible={visible} onClose={() => setVisible(false)} data={rescatToShare(props.rescat)}/>
      }
    </>
  );
}

const ShareButton = (props) => <ShareIcon {...props} button={true} />

export default ShareButton;
