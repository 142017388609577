import { Layout } from "antd";
import { HeartFilled, InstagramOutlined, TwitterOutlined, MailOutlined } from "@ant-design/icons";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa", paddingTop: 30, textAlign: "center" }}>
          <div>
            © 2021, fet amb {<HeartFilled style={{color: "#cc0000"}}/>} per <strong>Terrànima(l)</strong> per un 🌍 millor
          </div>
          <div>Sobre nosaltres</div>
          <div>
            <a href="https://www.instagram.com/terranimal_/" style={{marginLeft: 2, marginRight: 2}}><InstagramOutlined/></a>
            <a href="https://twitter.com/terranimal9" style={{marginLeft: 2, marginRight: 2}}><TwitterOutlined /></a>
            <a href="mailto:terranimal9@gmail.com" style={{marginLeft: 2, marginRight: 2}}><MailOutlined /></a>
          </div>
    </AntFooter>
  );
}

export default Footer;
