import { useState, useEffect } from 'react';
import {Spin, Button, Layout, Alert, Space} from 'antd';
import { PlusOutlined, BulbOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import cat from "../assets/cat-marker.png";
import dog from "../assets/dog-marker.png";
import ApiClient from '../services/ApiClient';
import MapCard from '../components/MapCard';
import Footer from "../components/layout/Footer";
import { icon } from "leaflet"
import { pointToLatLng, bboxToWtk } from '../utils';
import ForumAlert from "../components/ForumAlert";

const { Content } = Layout;

const MapIcons = {
  'cat': icon({
    iconUrl: cat,
    iconSize: [32, 32],
  }),
  'dog': icon({
    iconUrl: dog,
    iconSize: [32, 32],
  })
};

const Events = (props) => {
  const map = useMapEvents({
    moveend: () => {
      const bbox = map.getBounds();
      const wkt = bboxToWtk(bbox);
      console.debug('Setting bounding box to', wkt);
      if (props.setBbox) {
        props.setBbox(wkt);
      }
    },
  });
  return <></>
}


const Map = () => {
  const [ position, setPosition ] = useState(null);
  const [ geoError, setGeoError] = useState(false);
  const [ markers, setMarkers ] = useState([]);
  const history = useHistory();
  const [ bbox, setBbox ] = useState();

  useEffect(() => {
    if (!position) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const coords = [position.coords.latitude, position.coords.longitude];
        setPosition(coords);
        
      },
      (e) => {
        setGeoError(e);
        setPosition([41.979401, 2.821426])
        console.log('Unable to retrieve your location', e);
      }, {
        timeout: 5000
      });
    }
    const getMarkers = async () => {
      const request = await ApiClient.post('Rescat/from_bbox', {
        bbox: bbox
      });
      const markers = await request.data.res.map(item => {
        item.geog = pointToLatLng(item.geog);
        return item;
      });
      setMarkers(markers);
    }
    if (bbox) {
      getMarkers();
    }
  }, [bbox, position])
  if (!position) {
    return <Spin size="large" style={{width: "100%"}} tip="Obtenint ubicació..."/>
  }
  const MapItems = markers.map((marker) => (
    <Marker position={marker.geog} icon={MapIcons[marker.type]} key={marker.id}>
      <Popup>
        <MapCard rescat={marker}/>
      </Popup>
    </Marker>
  ))

  return (
    <Layout>
      <Content className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}>
        <div style={{marginBottom: 15}}>
          <ForumAlert />
        </div>
        {geoError && <Alert type="warning" message="Error ubicació" showIcon description={`Sembla que no es pot accedir a la ubicació. Error: ${geoError.message}`} style={{margin: 10}}/> }
    <MapContainer center={position} zoom={13} scrollWheelZoom={true} style={{width: "100%", height: 600}} whenReady={(e) => setBbox(bboxToWtk(e.target.getBounds()))}>
      <Events setBbox={setBbox}/>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {MapItems}
        </MapContainer>
        <div style={{textAlign: 'center', marginTop: 20}}>
          <Button type='primary' size='large' icon={<PlusOutlined />}
            onClick={(e) => history.push('/rescat/add')}
          >Afegir</Button>
        </div>
        </Content>
        <Footer />
        </Layout>
  )
}
export default Map;