import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Layout, Typography, Button } from 'antd';
import rainbow from "../assets/images/rainbow.svg";
import Footer from "../components/layout/Footer";
import { AnonymousApiClient } from "../services/ApiClient";
const { Title, Text, Paragraph } = Typography;


const { Content } = Layout;


const SignUpConfirm = (props) => {
  const { code } = useParams();
  const [ validating, setValidating ] = useState(false);
  const [ validateMessage, setValidateMessage ] = useState(false);
  const history = useHistory();

  const validate = async () => {
    setValidating(true);
    try {
      await AnonymousApiClient.post(
        '/ResUsers/signup_confirm_code',
        {
          code: code
        },
      );
      history.replace('/sign-in')
    }
    catch {
      setValidateMessage('Sembla que hi ha hagut un error al validar el codi.')
    }
    finally {
      setValidating(false);
    }
  }

  return (
    <Layout>
      <Content style={{ padding: '0 50px' }}>
        <div style={{textAlign: "center"}}>
          <Title>Gairebé tot a punt!</Title>
          {!(code)  &&
          <Text>Moltes gràcies per registrar-te, ara rebràs un correu electrònic per tal de confirmar el teu compte</Text>
          }
          {code &&
            <div>
            <Paragraph>Apreta el següent botó per tal de validar el teu compte</Paragraph>
            <Button type="primary" loading={validating} onClick={() => validate()}>
              {validating? "Validant" : "Validar compte"}
            </Button>
            {validateMessage &&
              <div>
                <Text type="danger">{validateMessage}</Text>
              </div>
            }
            </div>
          }
          <div>
            <img src={rainbow} alt="almost done!" style={{width: "300px"}}/>
          </div>
        </div>
      </Content>
      <Footer/>
    </Layout>
  )
}

export default SignUpConfirm;
