import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./Auth";

export default function PrivateRoute({ children, component, ...rest }) {
  const auth = useAuth();
  const element = children || component()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.token ? (
          element
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}