import {useState, useCallback, useEffect} from "react";
import { MapContainer, TileLayer, Marker, useMapEvent } from 'react-leaflet';
import { icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import marker from "../assets/images/marker.svg";
import { getDefaultLatLong } from "../utils";
import {Alert, Spin} from "antd";


const MarkerIcon = icon({
  iconUrl: marker,
  iconSize: [32, 32],
})


const Events = ({onChange}) => {
  useMapEvent('click', (e) => {
    const latLong = [e.latlng.lat, e.latlng.lng];
    console.log(latLong);
    if (onChange) {
     onChange(latLong);
    }
  });
  return <></>
}

const MapPosition = ({callback, defaultPosition}) => {
  const [ geoError, setGeoError ] = useState(null);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const coords = [position.coords.latitude, position.coords.longitude];
        console.log(`X Setting position to ${coords}`);
        callback(coords);

      },
      (e) => {
        setGeoError(e);
        callback(defaultPosition)
        console.log('Unable to retrieve your location', e);
      });
  }, []);
  if (geoError) {
    return (
      <Alert style={{marginTop: 10, marginBottom: 10}}
        type="warning"
        message="Error ubicació"
        showIcon
        description={`Sembla que no es pot accedir a la ubicació. Error: ${geoError.message}`}
      />
    );
  } else return null;
};


const MapForm = ({onChange, value, readonly = false}) => {
  const [ center, setCenter ] = useState(null);
  if (!center && value ) {
    setCenter(value);
  }
  return (
      <>
        {!readonly && <MapPosition callback={setCenter} defaultPosition={getDefaultLatLong}/>
        }
        {!center && !readonly &&
            <Spin size="large" style={{width: "100%"}} tip="Obtenint ubicació..."/>
        }
        {center &&
            <MapContainer center={center} zoom={16} scrollWheelZoom={false} style={{width: "100%", height: 300}}
                          zoomControl={false}>
              {!readonly &&
                  <Events onChange={onChange}/>
              }
              <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {value && <Marker position={value} icon={MarkerIcon}/>}
            </MapContainer>
        }
        </>
  )
}

export default MapForm;
