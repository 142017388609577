import {Form, Button, Input, Comment, Avatar, Checkbox, List, Alert} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {useAuth} from "../components/Auth";
import {useState, useEffect, useCallback} from "react";
import MapForm from "../components/MapForm";
import ApiClient, {AnonymousApiClient} from "../services/ApiClient";
import {latLngToPoint, pointToLatLng} from "../utils";
import Moment from "moment";
import Gravatar from "../components/Gravatar";
import Markdown from "../components/Markdown";

const {TextArea} = Input;

const RescatComents = (props) => {
  const auth = useAuth();
  const {rescat} = props;
  const [updateLocation, setUpdateLocation] = useState(false);
  const [sending, setSending] = useState(false);
  const [comments, setComments] = useState([]);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setSending(true);
    const postData = {
      rescat_id: rescat.id,
      content: values.content,
    }
    if (values.geog) {
      postData.geog = latLngToPoint(values.geog);
    }
    await ApiClient.post('/RescatComment', postData);
    setSending(false);
    setUpdateLocation(false);
    form.resetFields();
    await fetchComments();
  }

  const fetchComments = useCallback(async () => {
    const result = await AnonymousApiClient.get(`/RescatComment/from_slug`, {
      params: {
        'slug': rescat.slug
      }
    });
    setComments(result.data.res);
  }, [rescat]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments])

  return (
    <>
      <List
        header={`${comments.length} comentaris`}
        itemLayout="horizontal"
        dataSource={comments}
        renderItem={item => (
          <li key={item.id}>
            <Comment
              key={item.id}
              author={item.create_uid.name}
              avatar={<Gravatar gravatar={item.create_uid.gravatar}/>}
              content={<Markdown>{item.content}</Markdown>}
              datetime={Moment(item.create_date, 'YYYY-MM-DD').fromNow()}
            />
            {item.geog &&
              <MapForm readonly value={pointToLatLng(item.geog)}/>
            }
          </li>
        )}
      />
      {auth.token &&
        <Comment
          avatar={<Avatar icon={<UserOutlined/>}/>}
          content={
            <Form onFinish={onFinish} form={form}>
              <Form.Item
                name="content"
                rules={[
                  {required: true}
                ]}
              >
                <TextArea rows={4}/>
              </Form.Item>
              <Form.Item>
                <Checkbox checked={updateLocation} onChange={(event) => setUpdateLocation(event.target.checked)}>Afegir
                  nova ubicació</Checkbox>
              </Form.Item>
              {updateLocation &&
                <Form.Item name="geog">
                  <MapForm/>
                </Form.Item>
              }
              <Form.Item>
                <Button htmlType="submit" type="primary" loading={sending}>
                  Afegir comentari
                </Button>
              </Form.Item>

            </Form>
          }/>
      }
      {!auth.token &&
        <Alert
          description={<span><a href="/sign-up">Registra't</a> o <a href="/sign-in">inicia sessió</a> per tal de poder fer comentaris en aquest rescat</span>}
          type="info"
          showIcon
        />
      }
    </>
  )
}

export default RescatComents;
