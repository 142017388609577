import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Layout, Typography, Button, Form, Input, Row, Result } from 'antd';
import reset from "../assets/images/password_reset.svg";
import Footer from "../components/layout/Footer";
import { AnonymousApiClient } from "../services/ApiClient";
import {KeyOutlined, MailOutlined} from "@ant-design/icons";
const { Title, Text, Paragraph } = Typography;


const { Content } = Layout;

export const PasswordResetConfirm = () => {
  const { code } = useParams();
  const [ validating, setValidating ] = useState(false);
  const [ validateMessage, setValidateMessage ] = useState(false);
  const history = useHistory();

  const onFinish = async (values) => {
    setValidating(true);
    try {
      await AnonymousApiClient.post(
        '/ResUsers/password_reset_confirm',
        {
          code: code,
          password: values.password
        },
      );
      history.replace('/sign-in')
    }
    catch {
      setValidateMessage('Sembla que hi ha hagut un error al validar el codi.')
    }
    finally {
      setValidating(false);
    }
  }

  return (
    <Layout>
      <Content style={{ padding: '0 50px' }}>
        <div style={{textAlign: "center"}}>
          <Title>Restableix la teva contrasenya</Title>
        <Row type="flex" justify="center">

          <Form
                onFinish={onFinish}
                layout="vertical"
                style={{width: 300, marginTop: 20}}
              >
            <Form.Item
                  label="Contrasenya"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Contrasenya" prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />} />
                </Form.Item>
                <Form.Item
                  name="password2"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="Repetir la contrasenya" prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />} />
                </Form.Item>
            <Button
                    type="primary"
                    htmlType="submit"
                    loading={validating}
                    style={{ width: "100%", textTransform: "uppercase" }}
                  >
              {validating? "Enviant" : "Envia"}
            </Button>
          </Form>
            {validateMessage &&
              <div>
                <Text type="danger">{validateMessage}</Text>
              </div>
            }
        </Row>
          <div>
            <img src={reset} alt="almost done!" style={{width: "300px"}}/>
          </div>
      </div>
      </Content>
      <Footer/>
    </Layout>
  )
}


const PasswordReset = (props) => {
  const [ result, setResult ] = useState(false);

  const onFinish = async (values) => {
    await AnonymousApiClient.post('/ResUsers/password_reset', {
      email: values.email
    });
    setResult(true);
  }

  return (
    <Layout>
      <Content style={{ padding: '0 50px' }}>
        <div style={{textAlign: "center"}}>
          <Title>Recorda la teva contrasenya</Title>
          {!(result) &&
            <>
          <Text>Posa la teva adreça de correu electrònic per poder restablir la contrasenya.</Text>
              <Row type="flex" justify="center">
            <Form
                onFinish={onFinish}
                layout="vertical"
                style={{width: 300, marginTop: 20}}
              >
              <Form.Item
                label="Correu electrònic"
                name="email"
                rules={[
                  {
                    required: true
                  }
                  ]}
              >
                <Input placeholder="usuari@example.com" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.45)' }} />} />
              </Form.Item>
               <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", textTransform: "uppercase" }}
                  >
                    Recorda
                  </Button>
            </Form>
                </Row>
          </>
          }
          {result &&
          <>
            <Result
              status="success"
              title="Petició feta correctament"
              subTitle="Si la direcció del correu electrònic és correcte, rebràs un correu electrònic per restablir la contrassenya"
            />
          </>
          }
          <div>
            <img src={reset} alt="almost done!" style={{width: "300px"}}/>
          </div>
        </div>
      </Content>
      <Footer/>
    </Layout>
  )
}

export default PasswordReset;
