import React, { useContext, createContext, useState } from "react";
import Cookies from 'universal-cookie';
import ApiClient from "../services/ApiClient";

const cookies = new Cookies();


export const Auth = {
  isAuthenticated: !!cookies.get('token'),
  token: cookies.get('token'),
  async signin(username, password, remember = true) {
    try {
      if (!Auth.token) {
        const result = await ApiClient.get('/token', {
          auth: { username, password }
        });
        console.log("Login successful", result.data.token);
        let token = result.data.token;
        let maxAge = 3600;
        if (remember) {
          maxAge = 3600 * 24 * 31
        }
        console.debug(`Storing token ${token} in to the cookie for ${maxAge}`);
        cookies.set('token', token, { maxAge });
        Auth.isAuthenticated = true;
        Auth.token = token;
        return true
      }
    } catch (exc) {
      Auth.signout();
      return false;
    }
  },
  signout() {
    console.log('Signing out...');
    Auth.isAuthenticated = false;
    Auth.token = null;
    cookies.remove('token');
  }
};


const authContext = createContext();


export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}


export function useAuth() {
  return useContext(authContext);
}


function useProvideAuth() {
  const [token, setToken] = useState(cookies.get('token'));

  const signin = async (username, password, remember) => {
    const result = await Auth.signin(username, password, remember);
    setToken(Auth.token);
    return result;
  };

  const signout = () => {
    Auth.signout();
    setToken(Auth.token);
  };

  return {
    token,
    signin,
    signout
  };
}