import axios from "axios";
import { Auth } from "../components/Auth";

const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

ApiClient.interceptors.request.use((request) => {
  if (Auth.token) {
    request.headers['Authorization'] = `token ${Auth.token}`;
  } else {
    request.headers['Authorization'] = `token ${process.env.REACT_APP_ANONYMOUS_TOKEN}`;
  }
  return request;
});

ApiClient.interceptors.response.use((response) => {
  return response;
});

export const AnonymousApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

AnonymousApiClient.interceptors.request.use((request) => {
  request.headers['Authorization'] = `token ${process.env.REACT_APP_ANONYMOUS_TOKEN}`;
  return request;
});

export default ApiClient;