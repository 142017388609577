export const pointToLatLng = (point) => (
  point.replace('POINT(', '').replace(')', '').split(' ').map((item) => parseFloat(item))
);

export const latLngToPoint = (coords) => (
  `POINT (${coords[0]} ${coords[1]})`
);

export const bboxToWtk = (bbox) => (
  `POLYGON ((${bbox.getNorthEast().lat} ${bbox.getNorthEast().lng}, ${bbox.getNorthWest().lat} ${bbox.getNorthWest().lng}, ${bbox.getSouthWest().lat} ${bbox.getSouthWest().lng}, ${bbox.getSouthEast().lat} ${bbox.getSouthEast().lng}, ${bbox.getNorthEast().lat} ${bbox.getNorthEast().lng}))`
);

export const getDefaultLatLong = () => [41.979401, 2.821426];

export const rescatToShare = (rescat) => ({
    title: rescat.name,
    text: rescat.description || rescat.name,
    url: `${window.location.protocol}//${window.location.host}/rescat/${rescat.slug}`
});
