import {Card, Badge, Typography, Image} from 'antd';
import {
  CommentOutlined,
  FieldTimeOutlined
} from '@ant-design/icons';
import Moment from "moment";
import {useHistory} from "react-router-dom";
import {ShareIcon} from "./ShareButton";
import Gravatar from "./Gravatar";
import SubscriptionSwitch from "./SubscriptionSwitch";

const {Meta} = Card;
const {Text} = Typography;

export const states = {
  'lost': {text: 'Perdut', color: 'cyan'},
  'seen': {text: 'Vist', color: 'purple'}
}

const MapCard = (props) => {
  const {rescat} = props;
  const history = useHistory();
  const {text, color} = states[rescat.state];
  return (
    <>
      <Badge.Ribbon text={text} color={color}>
        <Card
          style={{width: 300}}
          cover={rescat.images.length ?
            <Image src={`${process.env.REACT_APP_MINIO_SERVER_URL}/images/rescat_image/${rescat.images[0]}_image`}
                   placeholder
                   style={{height: 170}}
            /> : null
          }
          actions={[
            <>
              <Badge count={rescat.comments.length}>
                <CommentOutlined key="comments" style={{fontSize: 28}}
                                 onClick={() => history.push(`/rescat/${rescat.slug}`)}/>
              </Badge>
              <div><Text type="secondary">Visualitzar</Text></div>
            </>
            ,
            <>
              <ShareIcon style={{fontSize: 28}} rescat={rescat}/>
              <div><Text type="secondary">Compartir</Text></div>
            </>
            ,
            <>
            <SubscriptionSwitch rescat={rescat}/>
              <div style={{marginTop: 8}}>
                <Text type="secondary">Notificacions</Text>
              </div>
            </>
          ]}
        >
          <Meta
            avatar={<Gravatar gravatar={rescat.author.gravatar}/>}
            title={
              <>
                {rescat.name}
                {rescat.last_seen_date &&
                  <div style={{fontSize: 12}}>
                    <Text type="secondary"><FieldTimeOutlined/> {Moment(rescat.last_seen_date, 'YYYY-MM-DD').fromNow()}
                    </Text>
                  </div>
                }
              </>
            }
            description={rescat.description}
          />
        </Card>
      </Badge.Ribbon>
    </>
  )
}

export default MapCard;