/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignUpConfirm from "./pages/SignUpConfirm";
import NotFound from "./pages/NotFound";
import SignIn from "./pages/SignIn";
import RescatAdd from "./pages/RescatAdd";
import PasswordReset, { PasswordResetConfirm } from "./pages/PasswordReset";
import RescatDetail from "./pages/RescatDetail";
import PrivateRoute from "./components/Route";
import { ProvideAuth } from "./components/Auth";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
  return (
    <div className="App">
      <ProvideAuth>
        <Switch>
          <Redirect from='/' exact to='/home' />
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/sign-up/confirm" exact component={SignUpConfirm} />
          <Route path="/sign-up/confirm/:code" exact component={SignUpConfirm} />
          <Route path="/sign-in" exact component={SignIn} />
          <Route path="/password_reset" exact component={PasswordReset} />
          <Route path="/password_reset/:code" exact component={PasswordResetConfirm} />
          <Route path="/404" exact component={NotFound} />
          <PrivateRoute exact path="/rescat/add" component={RescatAdd} />
          <Route exact path="/rescat/:slug" component={RescatDetail} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <Route path="*" exact component={NotFound} />
        </Switch>
      </ProvideAuth>
    </div>
  );
}

export default App;
